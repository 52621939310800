<!-- 免疫应激性 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText">将检测到的T细胞克隆与数据库进行比对，进行大数据前瞻性分析。</div>
        <div class="resultItem">
            <div style="margin-bottom: 16px;">
                <span class="globalText middle" style="line-height: 30px;">您本次检测应激性指数为<span
                        style="color:#FF830F;margin:0 4px;">{{ num }}，结果正常</span></span>
            </div>
        </div>

        <div style="display:flex;align-items: center;justify-content: space-between;">
            <div class="stressBox">
                <img src="../../../images/report/progressBgc.png" alt="" class="stressProgress stressBgc">
                <div class="stressUp" :style="{ width: proWidth + '%' }">
                    <img src="../../../images/report/progress.png" alt="" class="stressProgress">
                </div>
            </div>
            <img v-if="num < 0.43" src="../../../images/report/laughEmo.png" alt="" class="stressEmoicon">
            <img v-else-if="num < 0.87" src="../../../images/report/smileEmo.png" alt="" class="stressEmoicon">
            <img v-else src="../../../images/report/sadEmo.png" alt="" class="stressEmoicon">
        </div>

        <!-- 解释 -->
        <div class="globalTitle" style="color:#FF830F;margin:30px 0 16px 0;">指标解释</div>
        <div class="globalText">免疫应激性是人体免疫系统在应对外界病原体或内在疾病时所表现出的应答反应。机体处于应激状态时，免疫系统被激活，免疫细胞会大量增殖，分泌细胞因子及抗体，杀灭入侵病原体。</div>
        <div style="display:flex;align-items: center;justify-content: space-between; margin:20px 0;">
            <img src="../../../images/report/laugh.png" alt="" class="stressProgress">
            <img src="../../../images/report/laughEmo.png" alt="" class="stressEmoicon">
        </div>
        <div style="display:flex;align-items: center;justify-content: space-between; margin:20px 0;">
            <img src="../../../images/report/smile.png" alt="" class="stressProgress">
            <img src="../../../images/report/smileEmo.png" alt="" class="stressEmoicon">
        </div>
        <div style="display:flex;align-items: center;justify-content: space-between; margin:20px 0;">
            <img src="../../../images/report/sad.png" alt="" class="stressProgress">
            <img src="../../../images/report/sadEmo.png" alt="" class="stressEmoicon">
        </div>
        <div class="globalText">免疫应激性指数范围为0-1之间，指数越高，显示机体正处于感染炎症或其他疾病状态的可能越高。<span
                style="color:#FF830F">蓝色提示目前检测结果为正常，绿色提示需关注，橙色提示应激活跃需密切关注。</span></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            num: 0.06,
            proWidth: 0,
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
        this.proWidth = Math.floor(this.num * 20) * 4.76
    },
    computed: {
    },
    methods: {

    },
}
</script>
<style lang="scss" scoped>
.resultItem {
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 30px 0;
}

.result {
    margin: 8px 0 0 165px;
}

.stressBox {
    position: relative;
    width: 250px;
    height: 36px;
    padding-right: 2px;

    .stressBgc {
        position: absolute;
        top: 0;
        left: 0;
    }

    .stressUp {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        overflow: hidden;
    }
}

.stressProgress {
    width: 250px;
    height: 36px;
}

.stressEmoicon {
    width: 42px;
    height: 42px;
}
</style>
